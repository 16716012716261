import { useQuery } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import { internalApi } from "@/react/helpers/urlHelpers";

export const MARKETING_HUB_STATUS_QUERY_KEY = "marketing-hub-status";

const useMarketingHubStatusAPI = () =>
  useQuery(
    MARKETING_HUB_STATUS_QUERY_KEY,
    () => reactQueryGet(internalApi.marketingHubStatus.show()),
    {
      notifyOnChangeProps: "tracked",
    },
  );

interface MarketingHubStatusCircuitBreakers {
  broadcasts: boolean;
}

interface MarketingHubStatusNonAdminData {
  enabled: boolean;
  circuit_breakers: MarketingHubStatusCircuitBreakers;
}

interface MarketingHubStatusAdminData {
  enabled: boolean;
  trialing: boolean;
  lapsed: boolean;
  show_limits_notice: boolean;
  plan: {
    cancel_at_period_end: boolean;
    current_period_end: string;
    status: string;
  };
  limits: {
    marketing_email_limit_reached: boolean;
    marketing_email_limit: number;
    marketing_email_usage_in_period: number;
    marketing_email_limit_period_in_hours: number;
    allowance: number;
  };
  quarantine: {
    active: boolean;
    level: "normal" | "warning" | "suspend" | "blocked";
    marketing_email_limit: number;
  };
  circuit_breakers: MarketingHubStatusCircuitBreakers;
}

interface UseMarketingHubStatusContextReturn {
  refetch: () => Promise<any>;
  isLoading: boolean;
  data?: MarketingHubStatusNonAdminData | MarketingHubStatusAdminData;
}

export const useMarketingHubStatusContext =
  (): UseMarketingHubStatusContextReturn => {
    const { data, refetch, isLoading } = useMarketingHubStatusAPI();

    return {
      refetch,
      isLoading,
      data,
    };
  };
